import {IPortal} from '../portal/portal.model';
import {Moment} from 'moment';

export interface IMenu {
    id?: string;
    name?: string;
    title?: string;
    environment?: string;
    published?: boolean | null;
    targetUrl?: string;
    createDate?: Moment | null;
    modifiedDate?: Moment | null;
    portal?: IPortal | null;
    menus?: IMenu | null;
}

export class Menu implements IMenu {
    constructor(
        public id?: string,
        public name?: string,
        public title?: string,
        public environment?: string,
        public published?: boolean | null,
        public targetUrl?: string,
        public createDate?: Moment | null,
        public modifiedDate?: Moment | null,
        public portal?: IPortal | null,
        public menus?: IMenu | null
    ) {
        this.published = this.published ?? false;
    }
}

export function getMenuIdentifier(menu: IMenu): string | undefined {
    return menu.id;
}
