import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {getMenuIdentifier, IMenu} from '../menu.model';
import {createRequestOption} from '../../../../core/request/request-util';
import {isPresent} from '../../../../core/util/operators';
import moment from 'moment';
import {environment} from '../../../../../environments/environment';
import {endpoint} from '../../../../core/constants/services.constants';

export type EntityResponseType = HttpResponse<IMenu>;
export type EntityArrayResponseType = HttpResponse<IMenu[]>;

@Injectable({providedIn: 'root'})
export class MenuService {
    private URL_BASE: string = environment.url_services;
    protected resourceUrl = `${this.URL_BASE}${endpoint.ENDPOINT_MENU}`;

    constructor(protected http: HttpClient) {
    }

    create(menu: IMenu): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(menu);
        return this.http
            .post<IMenu>(this.resourceUrl, copy, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    update(menu: IMenu): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(menu);
        return this.http
            .put<IMenu>(`${this.resourceUrl}/${getMenuIdentifier(menu) as string}`, copy, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    partialUpdate(menu: IMenu): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(menu);
        return this.http
            .patch<IMenu>(`${this.resourceUrl}/${getMenuIdentifier(menu) as string}`, copy, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http
            .get<IMenu>(`${this.resourceUrl}/${id}`, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http
            .get<IMenu[]>(this.resourceUrl, {params: options, observe: 'response'})
            .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
    }

    delete(id: string): Observable<HttpResponse<{}>> {
        return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    addMenuToCollectionIfMissing(menuCollection: IMenu[], ...menusToCheck: (IMenu | null | undefined)[]): IMenu[] {
        const menus: IMenu[] = menusToCheck.filter(isPresent);
        if (menus.length > 0) {
            const menuCollectionIdentifiers = menuCollection.map(menuItem => getMenuIdentifier(menuItem));
            const menusToAdd = menus.filter(menuItem => {
                const menuIdentifier = getMenuIdentifier(menuItem);
                if (menuIdentifier == null || menuCollectionIdentifiers.includes(menuIdentifier)) {
                    return false;
                }
                menuCollectionIdentifiers.push(menuIdentifier);
                return true;
            });
            return [...menusToAdd, ...menuCollection];
        }
        return menuCollection;
    }

    protected convertDateFromClient(menu: IMenu): IMenu {
        return Object.assign({}, menu, {
            createDate: menu.createDate?.isValid() ? menu.createDate.toJSON() : undefined,
            modifiedDate: menu.modifiedDate?.isValid() ? menu.modifiedDate.toJSON() : undefined,
        });
    }

    protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
        if (res.body) {
            res.body.createDate = res.body.createDate ? moment(res.body.createDate) : undefined;
            res.body.modifiedDate = res.body.modifiedDate ? moment(res.body.modifiedDate) : undefined;
        }
        return res;
    }

    protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
        if (res.body) {
            res.body.forEach((menu: IMenu) => {
                menu.createDate = menu.createDate ? moment(menu.createDate) : undefined;
                menu.modifiedDate = menu.modifiedDate ? moment(menu.modifiedDate) : undefined;
            });
        }
        return res;
    }
}
